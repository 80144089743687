<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <alert type="info" text="The description will appear on a clients invoice." />
      </v-col>

      <v-col cols="12">
        <hub-text-field
          @keyup="onInputFocus('description')"
          @blur="onInputBlur"
          :error-messages="getErrorMessages('description')"
          name="description"
          v-model="formData.description"
          :label="$t('common.description')"
        />
      </v-col>

      <v-col cols="12">
        <store-data-dropdown
          name="shippingServiceCode"
          v-model="formData.shippingServiceCode"
          data-getter="billing/getVisibleShippingServices"
          item-value-key="code"
          item-text-key="name"
          :label="$t('billing.shippingService')"
          :error-messages="getErrorMessages('shippingServiceCode')"
        />
      </v-col>

      <v-col cols="12">
        <store-data-dropdown
          name="originRegionDropdown"
          v-model="formData.originRegion"
          data-getter="billing/getOriginRegions"
          item-value-key="code"
          item-text-key="displayName"
          :label="$t('billing.originRegion')"
          :error-messages="getErrorMessages('originRegion')"
        />
      </v-col>

      <v-col cols="12">
        <alert type="info" text="Add the destination zone or set as DEFAULT." />
      </v-col>

      <v-col cols="6">
        <store-data-dropdown
          name="destinationRegionDropdown"
          v-model="formData.destinationRegion"
          data-getter="billing/getRegions"
          data-path="data"
          item-value-key="code"
          item-text-key="displayName"
          :label="$t('billing.destinationRegion')"
          :error-messages="getErrorMessages('destinationRegion')"
        />
      </v-col>

      <v-col cols="6">
        <store-data-dropdown
          name="destinationZone"
          v-model="formData.destinationZone"
          data-getter="billing/getRowZones"
          item-value-key="code"
          item-text-key="description"
          :label="$t('billing.destinationZone')"
          :error-messages="getErrorMessages('destinationZone')"
        />
      </v-col>

      <v-col cols="6">
        <store-data-dropdown
          name="containerId"
          v-model="formData.containerId"
          data-getter="billing/getContainerTypeCodes"
          item-value-key="id"
          item-text-key="description"
          :label="$t('billing.containerType')"
          :error-messages="getErrorMessages('containerId')"
        />
      </v-col>

      <v-col cols="6">
        <hub-text-field
          @keyup="onInputFocus('weight')"
          @blur="onInputBlur"
          :error-messages="getErrorMessages('weight')"
          name="weight"
          v-model="formData.weight"
          :label="$t('common.weight') + ' (g)'"
          persistent-hint
        />
      </v-col>

      <v-col cols="6">
        <hub-text-field
          @keyup="onInputFocus('price')"
          @blur="onInputBlur"
          :error-messages="getErrorMessages('price')"
          name="price"
          v-model="formData.price"
          :label="$t('common.price') + currencyLabelSuffix"
          persistent-hint
        />
      </v-col>

      <v-col cols="6">
        <hub-text-field
          @keyup="onInputFocus('additionalPricePerKilo')"
          @blur="onInputBlur"
          :error-messages="getErrorMessages('additionalPricePerKilo')"
          name="additionalPricePerKilo"
          v-model="formData.additionalPricePerKilo"
          :label="$t('billing.additionalPricePerKilo') + currencyLabelSuffix"
          persistent-hint
        />
      </v-col>

      <v-col cols="6">
        <hub-text-field
          @keyup="onInputFocus('surcharge')"
          @blur="onInputBlur"
          :error-messages="getErrorMessages('surcharge')"
          name="surcharge"
          v-model="formData.surcharge"
          :label="$t('common.surcharge') + ' (%)'"
          persistent-hint
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/default/Alert.vue'
import StoreDataDropdown from '@/components/forms/StoreDataDropdown.vue'
import { HubTextField } from '@hubootech/ui-library'

export default {
  name: 'InputFields',
  components: { Alert, StoreDataDropdown, HubTextField },
  props: {
    formData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('core', { errorMessages: 'validationErrors' }),
    currencyLabelSuffix() {
      let currency = this.$store.getters['billing/getSelectedBox'].currency
      if (currency) return ` (${currency})`
      return ''
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.activeField = inputName
    },
    onInputBlur() {
      this.activeField = ''
    },
    getErrorMessages(fieldName) {
      if (!this.errorMessages) return []

      return this.errorMessages[fieldName] ?? []
    },
  },
}
</script>

<style scoped>
.v-input {
  min-height: 78px;
}
</style>
