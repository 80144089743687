<template>
  <modal-form
    :value="value"
    id="edit-fulfilment-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('fulfilmentPricing.editTitle') }}
    </template>

    <template #fields>
      <InputFields :form-data="valuesToString" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import InputFields from './InputFields.vue'
export default {
  name: 'FulfilmentPricingEditModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
    selected: { type: Object, required: true },
  },

  data() {
    return {
      formData: {
        shippingServiceCode: this.selected.shippingServiceCode ?? '',
        description: this.selected.description ?? '',
        originRegion: this.selected.originRegion ?? '',
        destinationRegion: this.selected.destinationRegion ?? '',
        destinationZone: this.selected.destinationZone ?? '',
        containerId: this.selected.containerId ?? '',
        weight: this.selected.weight ?? '',
        price: this.selected.price ?? '',
        additionalPricePerKilo: this.selected.additionalPricePerKilo ?? '',
        surcharge: this.selected.surcharge ?? '',
      },
      activeField: '',
    }
  },

  computed: {
    box() {
      return this.$store.getters['billing/getSelectedBox'].hubooBox || 0
    },

    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/fulfilment-price-list/' + this.selected?.id,
      })
    },

    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },

    valuesToString() {
      return this.convertValues(this.formData)
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('fulfilmentPricing/update', {
        id: this.selected?.id,
        data: {
          ...this.valuesToString,
          price: parseFloat(this.valuesToString.price) || this.valuesToString.price,
          additionalPricePerKilo:
            parseFloat(this.valuesToString.additionalPricePerKilo) ||
            this.valuesToString.additionalPricePerKilo,
          surcharge: parseFloat(this.valuesToString.surcharge) || this.valuesToString.surcharge,
          hubooBox: this.box,
        },
      })

      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },

    convertValues(object) {
      const convertedObject = {}
      // We need to convert the values to string.
      Object.keys(object).forEach(key => {
        convertedObject[key] = object[key].toString()
      })
      return convertedObject
    },
  },
}
</script>

<style lang="scss" scoped></style>
